import { Component } from '@angular/core';
import {JsonService} from "../services/json.service"
import {UserService} from "../services/user.service"
import {User} from "../models/user.model"
import {Marque} from "../models/marque.model"
import {MarqueService} from "../services/marque.service"
import { SlugifyPipe } from '../pipes/slugify.pipe';

@Component({
  selector: 'ns-adn',
  templateUrl: '../templates/backend/adn.component.html'
})
export class AdnComponent {
  public hasAdn: boolean;
  public adn: Array<any>;
  public sectorPositioning: number;
  public user: User;
  public launchAnimation: boolean;
  public ready: boolean = false;
  public marque: Marque;

  constructor(
    private api: JsonService,
    private userService : UserService,
    private marqueService: MarqueService,
    private slugifyPipe: SlugifyPipe
  ) { }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getData()
      this.userService.getUser().subscribe(response => {
        if (response instanceof User) {
          this.user = response;
          return this.user;
        }
      });
    })
  }

  getData() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
      }

      this.api.getAdn().subscribe(data => {
        this.adn = data.adn;
        this.sectorPositioning = data.sectorPositioning
        if (data.adn !== null) {
          this.hasAdn = data.adn['form'].length;
        } else {
          this.hasAdn = false;
        }
        this.ready = true;
        setTimeout(() => {
          this.launchAnimation = true;
        }, 100);
      });
    })
  }

  calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }
  calculateCircleAnime(pourcentage) {
    if (this.launchAnimation) {
      return { 'stroke-dashoffset': 289.067- (289.067 * pourcentage)/100 +'px' };
    }
  }

  collapseExpand(categoryName: string, index: string) {
    const id = 'collapse_' + this.slugifyPipe.transform(categoryName) + '_' + index;
    document.getElementById(id).classList.toggle('custom-collapse-opened');
  }

  downloadExportExcel() {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getAdnExcelExport().subscribe((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = 'adn.xlsx';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
  }

  downloadDocument() {
    const newTab = window.open();
    newTab.document.title = 'Chargement du Pdf...';
    this.api.getAdnPdf().subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  downloadAttestation() {
    const newTab = window.open();
    newTab.document.title = 'Chargement du Pdf...';
    this.api.getAttestationPdf().subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

}
