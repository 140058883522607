import {AfterViewInit, Component} from '@angular/core';
import {JsonService} from "../services/json.service";
import {MarqueService} from "../services/marque.service"
import {Marque} from "../models/marque.model"
import {SubCategory} from "../models/subcategory.model"

@Component({
  selector: 'ns-upgrades',
  templateUrl: '../templates/upgrades.component.html',
  styleUrls: ['../../assets/scss/pages/_upgrades.scss']
})

export class UpgradesComponent implements AfterViewInit {
  title = 'angularfront'


  public catSelected: string;
  public content: any;
  public ready: boolean = false;
  public marque: Marque;
  public launchAnimation: boolean;
  public colorMarqueOnly: boolean = false;
  public downloadButtonState: 'upgrades-bp-enabled' | 'upgrades-bp-disabled' = 'upgrades-bp-enabled';
  public isDownloading: boolean = false;

  constructor (private api: JsonService,  private marqueService: MarqueService) {
    this.catSelected = null;
    this.content = [];
  }

  ngOnInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        if (this.marque.slug === 'grandlyon') {
          this.colorMarqueOnly = true
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.getUpgrades();
  }

  getUpgrades() {
    this.api.getUpgrades().subscribe(content => {
      this.content = content;
      this.ready = true;
      setTimeout(() => {this.launchAnimation = true; }, 100);
    });
  }

  downloadUpgrades() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.downloadButtonState = 'upgrades-bp-disabled';
    const newTab = window.open();
    newTab.document.title = 'Chargement du Pdf...';
    this.api.getUpgradesPdf().subscribe((blobby) => {
      this.isDownloading = false;
      this.downloadButtonState = 'upgrades-bp-enabled';
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  public calculateWidth(pourcentage) {
    if(this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }

  getGradientClass(subCategory: object) {
    return !this.colorMarqueOnly ? 'bg-gradient-'+subCategory['categoryNameSlug'] : ''
  }

  hasExternalLink(recommendation, msg) {
    return !!this.getExternalLink(recommendation, msg)
  }

  getExternalLink(recommendation, msg) {
    return recommendation?.externalLinks?.[msg];
  }
}
