import { Injectable } from '@angular/core';
import { JsonService } from "./json.service"
import { of } from "rxjs"
import { Marque } from "../models/marque.model"

@Injectable({
  providedIn: 'root'
})
export class MarqueService {
  private marque: Marque;

  public readonly pointBorderColor = {
    genethic: 'rgba(145, 222, 204, 1)',
    aderly: 'red',
    grandlyon: 'red',
    rennes: '#00a897',
    mcapital: '#FF6720',
    bordeaux: '#f3a140',
    village: '#f3a140',
    ninkasi: '#ff3232',
    lille: '#E30513',
    paca: '#0075BF',
  } as const;

  public readonly backgroundColor = {
    genethic: 'rgba(145, 222, 204, 0.2)',
    aderly: 'rgba(243, 153, 153, 0.2)',
    grandlyon: 'rgba(243, 153, 153, 0.2)',
    rennes: 'rgba(0, 168, 151, 0.2)',
    mcapital: 'rgba(255,103,32, 0.2)',
    bordeaux: 'rgba(243,161,161, 0.2)',
    village:  'rgba(243,161,161, 0.2)',
    ninkasi: 'rgba(255, 50, 50, 0.2)',
    lille: 'rgba(227, 5, 19, 0.2)',
    paca: 'rgba(0, 117, 191, 0.2)',
  } as const;

  public readonly borderColor = {
    genethic: '#1FC5AF',
    aderly: '#f66767',
    grandlyon: '#f66767',
    rennes: '#00a897',
    mcapital: '#FF6720',
    bordeaux: '#f3a140',
    village: '#f3a140',
    ninkasi: '#ff3232',
    lille: '#E30513',
    paca: '#0075BF',
  } as const;

  constructor(private jsonService: JsonService) {
  }

  getMarque() {
    if (this.marque) {
      return of(this.marque);
    } else {
      let marqueObservable = this.jsonService.getMarque()
      marqueObservable.subscribe(marqueResponse => {
        if (marqueResponse instanceof Marque) {
          this.marque = marqueResponse;
          if (this.marque.slug === 'grandlyon') {
            window.document.title = 'Kelimpact, la plateforme de mesure d’impacts territoriaux de la Métropole de Lyon';
          } else if (this.marque.slug === 'lille') {
            window.document.title = 'Métropole Européenne de Lille';
          } else {
            window.document.title = this.marque.label;
          }
        }
      });
      return marqueObservable
    }
  }
}
