import { Component, OnInit, ViewChild } from '@angular/core';
import {JsonService} from "../../../services/json.service"
import {UserService} from "../../../services/user.service"
import {User} from "../../../models/user.model"
import {ActivatedRoute, ParamMap} from "@angular/router"
import { SlugifyPipe } from '../../../pipes/slugify.pipe';
import { MarqueService } from '../../../services/marque.service';
import { Marque } from '../../../models/marque.model';
import { catchError, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

/**
 * Allows an admin user to see the adn page of the given user/questionnaire
 */
@Component({
  selector: 'ns-admin-adn',
  templateUrl: './admin-adn.component.html'
})
export class AdminAdnComponent {
  public hasAdn: boolean;
  public adn: Array<any>;
  public user: User;
  public ready: boolean = false;
  public userId: number;
  public questionnaireResponseId: number;
  public sectorPositioning: number;
  public launchAnimation: boolean;
  public marque: Marque;
  public dateSaved: Date;

  constructor(
    private api: JsonService,
    private userService: UserService,
    private marqueService: MarqueService,
    private route: ActivatedRoute,
    private slugifyPipe: SlugifyPipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = parseInt(params.get('userId'))
      this.questionnaireResponseId = parseInt(params.get('questionnaireResponseId'))
    })
  }

  ngAfterViewInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
      }
    });
    this.getData();
  }

  calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }
  calculateCircleAnime(pourcentage) {
    if (this.launchAnimation) {
      return { 'stroke-dashoffset': 289.067- (289.067 * pourcentage)/100 +'px' };
    }
  }

  collapseExpand(categoryName: string, index: string) {
    const id = 'collapse_' + this.slugifyPipe.transform(categoryName) + '_' + index;
    document.getElementById(id).classList.toggle('custom-collapse-opened');
  }

  getData() {
    this.api.getUserByIdAsAdmin(this.userId).subscribe(data => {
      this.user = data as User;
    });

    this.api.getAdnByQuestionnaireResponseIdAsAdmin(this.questionnaireResponseId)
    .subscribe(
      data => {
        this.adn = data.adn;
        this.sectorPositioning = data['sectorPositioning'] || 0;
        if (data.adn !== null) {
          this.hasAdn = data.adn['form'].length;
        } else {
          this.hasAdn = false;
        }
        this.ready = true;
        setTimeout(() => {
          this.launchAnimation = true;
        }, 100);
      },
      err => {
        this.toastr.warning('Le questionnaire n\'a pas pu être trouvé')
      }
    );

    this.api.getQuestionnaireDateSaved(this.questionnaireResponseId).pipe(take(1)).subscribe((res: any) => {
      if ('dateSaved' in res) {
        this.dateSaved = this.getDateFromDateSaved(res.dateSaved);
      }
    })
  }

  downloadUpgradesPdf() {
    const newTab = window.open();
    newTab.document.title = 'Chargement des recommandations en Pdf...';
    this.api.getUpgradesPdfAsAdmin(this.questionnaireResponseId).subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  downloadExportPdf() {
    const newTab = window.open();
    newTab.document.title = 'Chargement de l\'ADN en Pdf...';
    this.api.getAdnPdfExportAsAdmin(this.questionnaireResponseId).subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  downloadFullQrPdf() {
    const newTab = window.open();
    newTab.document.title = 'Chargement du détail de l\'évaluation en Pdf...';
    this.api.getFullQrPdfExportAsAdmin(this.questionnaireResponseId).subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  private getDateFromDateSaved(dateSaved: any): Date {
    // qr.dateSaved has a weird format...
    if ('date' in dateSaved) {
      return new Date((dateSaved as any).date);
    }
    return new Date(dateSaved);
  }
}
