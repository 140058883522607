import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { Marque } from '../models/marque.model';
import { MarqueService } from '../services/marque.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ns-home',
  templateUrl: '../templates/backend/index.component.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('firstLoginModal') firstLoginModal: ElementRef;
  public user: User;
  public marque: Marque;
  public ready: boolean = false;
  public introText = {
    genethic: `<h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés
                    </p>`,
    aderly: `
        <h3>BIENVENUE SUR</h3>
        <h3>VOTRE TABLEAU DE BORD !</h3>
        <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés</p>
    `,
    grandlyon: `
        <h3>BIENVENUE SUR</h3>
        <h3>VOTRE TABLEAU DE BORD !</h3>
        <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés</p>
    `,
    rennes: `
        <h3>BIENVENUE SUR</h3>
        <h3>VOTRE TABLEAU DE BORD !</h3>
        <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés</p>
    `,
    mcapital: `<h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés
                    </p>`,
    bordeaux: `<h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés
                    </p>`,
    village: `<h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés
                    </p>`,
    ninkasi: `<h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés
                    </p>`,
    lille: `
                    <h3>BIENVENUE SUR</h3>
                    <h3>VOTRE TABLEAU DE BORD !</h3>
                    <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés</p>
                `,
    paca: `
        <h3>BIENVENUE SUR</h3>
        <h3>VOTRE TABLEAU DE BORD !</h3>
        <p>Évaluez votre performance ESG, suivez votre progression et accédez à des conseils personnalisés</p>
    `,
  };

  public popinText = {
    aderly: {
      title: 'Bienvenu.e.s sur votre tableau de bord !',
      content: `
        <p>Bienvenu.e.s dans votre espace dédié à notre collaboration à venir, pour contribuer au développement économique équilibré de notre territoire.</p>
        <p>En tant que Directeur Adjoint de l’ADERLY, je me réjouis de votre intérêt pour notre économie et espère que nous pourrons avancer ensemble autour de votre projet.</p>
        <p>Soucieux de l’empreinte socioéconomique et carbone que nos actions peuvent avoir, j’attache beaucoup d’importance à la qualité des contributions économiques à venir, et travaille avec une équipe engagée dans ce sens que notre mission d’accompagnement soit exemplaire</p>
        <p>
            Je vous laisse plonger dans notre démarche, et vous souhaite un excellent questionnaire organisé
            suivant 5 thématiques : stratégie, organisation, production, infrastructure, employé.e.s. et vous souhaite un excellent questionnaire.
        </p>
        <p></p>
        <p>Aymeric DE MOLLERAT</p>
    `,
    },
    grandlyon: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme, spécifique par son approche plurielle de l’impact et la prise en compte des enjeux territoriaux, a pour but de vous permettre d’évaluer vos impacts, d’identifier
            des axes d’amélioration et de suivre vos progrès dans le temps.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
            Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p>Lorsque la question porte sur le périmètre géographique de la Métropole de Lyon, cela est précisé dans l’intitulé. Dans le cas contraire, les données s’apprécient
            à l’échelle de l’ensemble de l’entreprise.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    rennes: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme vous est proposée gratuitement par Rennes Métropole.</p>
        <p>Elle vous permettra de balayer les différents piliers de la transition écologique et sociale à l'échelle de votre entreprise. L'objectif est d'identifier des axes d'amélioration et de suivre vos progrès dans le temps. Cet outil vous permettra aussi de commencer à vous mettre en mouvement sur certains sujets, ou d'aller plus loin, en vous proposant des premières pistes d'action en fonction de vos réponses.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    bordeaux: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme, spécifique par son approche plurielle de l’impact et la prise en compte des enjeux territoriaux, a pour but de vous permettre d’évaluer vos impacts, d’identifier
            des axes d’amélioration et de suivre vos progrès dans le temps.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    mcapital: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
  <p>En tant qu’entreprise financée par le groupe M Capital, vous disposez d’un espace dédié à votre stratégie ESG qui vous permet d’évaluer vos impacts environnementaux et sociaux,
   d’identifier des axes d’amélioration et de suivre vos progrès dans le temps.</p>
  <p>Animés par notre volonté de travailler à un nouveau modèle dans le monde de l’investissement, nous souhaitons contribuer à trouver un équilibre entre nos besoins présents et futurs et
  la santé de la planète.</p>
  <p>Cet objectif commun requiert la participation de tous, c’est pourquoi nous vous invitons, au travers du questionnaire conçu en partenariat avec Gen’éthic, à identifier
  les bonnes pratiques existantes au sein de votre entreprise, ainsi que les axes de progrès prioritaires. Cette plateforme deviendra rapidement, nous l’espérons,
  l’outil indispensable de votre démarche vers une nouvelle économie résiliente et inclusive.</p>
  <p>Bonne navigation.</p>
  <p>La Direction Développement Durable et Innovation M Capital</p>
    `,
    },
    village: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme, spécifique par son approche plurielle de l’impact et la prise en compte des enjeux territoriaux, a pour but de vous permettre d’évaluer vos impacts, d’identifier
            des axes d’amélioration et de suivre vos progrès dans le temps.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    ninkasi: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme, qui vous est mise à disposition par le Groupe Ninkasi, a pour but de vous permettre d’évaluer vos impacts, d’identifier des axes d’amélioration et de suivre vos progrès dans le temps.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    lille: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme vous est proposée gratuitement par la Métropole Européenne de Lille.</p>
        <p>Elle vous permettra de balayer les différents piliers de la transition écologique et sociale à l'échelle de votre entreprise. L'objectif est d'identifier des axes d'amélioration et de suivre vos progrès dans le temps. Cet outil vous permettra aussi de commencer à vous mettre en mouvement sur certains sujets, ou d'aller plus loin, en vous proposant des premières pistes d'action en fonction de vos réponses.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
    paca: {
      title: 'BIENVENUE SUR VOTRE TABLEAU DE BORD !',
      content: `
        <p>Cette plateforme vous est proposée gratuitement par la Région Sud - Provence Alpes Côte d'Azur.</p>
        <p>Elle vous permettra de balayer les différents piliers de la transition écologique et sociale à l'échelle de votre entreprise. L'objectif est d'identifier des axes d'amélioration et de suivre vos progrès dans le temps. Cet outil vous permettra aussi de commencer à vous mettre en mouvement sur certains sujets, ou d'aller plus loin, en vous proposant des premières pistes d'action en fonction de vos réponses.</p>
        <p>Pour assurer une bonne expérience, vous aurez la possibilité de commencer le questionnaire, de l’interrompre puis d’y revenir par la suite sans perdre vos données.</p>
        <p>Attention, si vous cliquez sur le bouton "valider" à la fin du questionnaire, la validation sera définitive et vous ne pourrez plus modifier vos réponses à moins de remplir le questionnaire à nouveau dans sa globalité.</p>
        <p>Vos données ainsi que les résultats obtenus ne sont pas partagés avec des tiers. Vous aurez également, à tout moment, la possibilité de supprimer votre compte.</p>
        <p></p>
        <p>Bonne navigation</p>
    `,
    },
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private marqueService: MarqueService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.ready = false;
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.ready = true;
      }
    });
  }

  ngAfterViewInit() {
    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response;
        if (this.user.isReseau()) {
          this.router.navigate(['/network']);
        } else if (this.user.isAdmin()) {
          this.router.navigate(['/admin']);
        }

        if (!this.user.hasAlreadyLogin && this.marque !== null && this.marque.slug in this.popinText) {
          this.modalService.open(this.firstLoginModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'lg',
            windowClass: 'has-already-login-modal',
            container: '.app-body',
          }).result.then(() => { });
          this.userService.notifyFirstLogin().subscribe();
        }
      }
    });
  }

}
